import { createGatsbyPageComponent } from '@shapeable/ui';
import { graphql } from 'gatsby';

export default createGatsbyPageComponent('Page');

export const query = graphql`
  query PageQuery($id: ID!) {
    platform {
      page(id: $id) {
        id slug path name __typename _schema { label pluralLabel }
        title pretitle subtitle
        titleRich { text }
        subtitleRich { text }
        content { text }
        options { text }
        openGraph { title description { plain } }
        intro { text }
        outro { text }
        type { id name slug }
        themeMode { slug }
        bannerThemeMode { slug }
        orderNumber
        openGraph { 
          image { url url2x thumbnails { card { url url2x } bubble { url url2x } } }
        }
        layout {
          id name slug component 
        }
        authors {
          id name slug path
          organisation { id name }
          linkedin
          photo { id url url2x }
          positions {
            id
            primary
            positions {
              id 
              name 
            }
            organisation { id name }
          }
        }
        files {
          name id path
          file { id url }
        }
        videos {
          id name url
          thumbnail { id url url2x }
        }
        imageAssets {
          id slug name image { id url }
        }
        people {
          id name slug path
          organisation { id name }
          position
          photo { id url url2x }
        }
        featurePosts {
          __typename id 
          name 
          path slug 
          title 
          subtitle 
          pretitle 
          openGraph { description { plain } image { url url2x } }
          intro { text }
          videos {
            id name url
            thumbnail { id url url2x }
          }
          imageAssets {
            id name image { id url url2x width height }
          }
          banner {
            name slug alternateText
            thumbnail { 
              id url url2x width height
            }
          }
        }
        embeds {
          citations {
            slug url name authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
          }
          pages {
            id name slug path
            openGraph {
              title description { plain }
              image { url url2x }
            }
          }
          people {
            id name slug path
            organisation { id name }
            position
            photo { id url url2x }
          }
          imageAssets {
            id slug name image { id url url2x }
          }
        }
        
        parent {
          __typename id name path slug title subtitle pretitle 
          content { text }
          children {
            __typename id name path slug title subtitle pretitle 
            openGraph { description { text } }
            intro { text }
            orderNumber
            banner {
              name slug alternateText
              thumbnail { 
                id url url2x width height
              }
            }
          }
        }

        featurePages {
          __typename id name path slug title subtitle pretitle 
          openGraph { description { plain } }
          intro { text }
          orderNumber
          layout { name slug }
          videos {
            id name url
            thumbnail { id url url2x }
          }
          banner {
            name slug alternateText
            thumbnail { 
              id url url2x width height
            }
          }
        }
        marqueePages {
          __typename id name path slug title subtitle pretitle 
          openGraph { description { plain } }
          intro { text }
          orderNumber
          videos {
            id name url
            thumbnail { id url url2x }
          }
          layout { name slug }
          banner {
            name slug alternateText
            thumbnail { 
              id url url2x width height
            }
          }
        }
        children {
          __typename id name path slug title subtitle pretitle 
          openGraph { description { text } image { id url url2x thumbnails { medium { url url2x } } } }
          intro { text(truncate: {words: 30}) }
          orderNumber
          type { id slug name }
          banner {
            image { 
              id url url2x width height
            }
            name slug alternateText
            thumbnail { 
              id url url2x width height
            }
          }
        }
        banners {
          id name slug title description { html } alternateText
          image { 
            id url url2x width height
          }
        }
        slices {
          id name slug title subtitle created updated
          icon { component }
          intro { text }
          outro { text }
          themeMode { slug }
          images {
            id name slug alternateText description { text }
            image { 
              id url url2x width height
            }
          }
          videos {
            id name url
            thumbnail { id url url2x }
          }
          embeds { 
            imageAssets {
              id slug name image { id url }
            }
            citations {
              slug url name authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
            }
          }
          pages {
            id slug name path disabled
          }
          people {
            __typename
            bio { text html }
            id name slug photo { url }
            position
            organisation { id name }
          }
          links {
            id name page { id path } label url isDownload
          }
          options { text }
          files {
            id
            name
            slug
            file {
              id
              url
            }
            thumbnail {
              url url2x
            }
          }
          image {
						image {
              url url2x
            }
          }
          color { value }
          backgroundColor { value }
          backgroundImages {
            image {
              url
            }
          }
          blocks {
            id name slug title subtitle pretitle
            link { id url name page { path } }
            page { id path name }
            layout { component }
            content { text }
            options { text }
            image { id name image { url } }
          }
          content { text }
          image { id }
          layout { id name slug component }
          organisations {
            id
            url
            logo {
              id
              url
            }
            logoInverted {
              id
              url
            }
          }
        }
      }
    }
  }
`;
